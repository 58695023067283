import React, { useState, useRef, useEffect } from 'react';
import { Form, Button, Dropdown, Row, Col, Alert } from 'react-bootstrap';
import axios from 'axios';

const MyForm = ({ http_url, currentUser, handleSuccess }) => {

    const nftCollection = useRef();
    const contractAddress = useRef();
    const tokenId = useRef();
    const oApiKey = useRef();
    const floorBuyPrice = useRef();
    const startAmount = useRef();
    const mnuemonic = useRef();
    const yourAddress = useRef();

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const nft_nickname = useRef();

    async function handleNFTBotStart(e) {
        e.preventDefault();
        await axios
            .post(
                http_url + '/launch_nft_bot/' + currentUser.email,
                {
                    settings: {
                        nickname: nft_nickname.current.value,
                        nftCollection: nftCollection.current.value,
                        contractAddress: contractAddress.current.value,
                        oApiKey: oApiKey.current.value,
                        tokenId: tokenId.current.value,
                        floorBuyPrice: floorBuyPrice.current.value,
                        startAmount: startAmount.current.value,
                        mnuemonic: mnuemonic.current.value,
                        yourAddress: yourAddress.current.value,
                    },
                },
            )
            .then((response) => {

                setSuccess("Success")
                handleSuccess()
            })
            .catch((error) => {
                setError('Unknown error encountered.');
            });
    }


    return (
        <div>
            {error && <Alert variant="danger">{error}</Alert>}
            {success && <Alert variant="success">{success}</Alert>}

            <Form onSubmit={handleNFTBotStart} className="dark-form px-3">
                <Row className='mt-3'>
                    <Col md='12' lg='6'>
                        <Form.Group id="nft-collection">
                            <Form.Label>NFT Collection</Form.Label>
                            <Form.Control type="text" ref={nftCollection} required placeholder='boredapeyachtclub' />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group id="nft_nickname">
                            <Form.Label>Bot Nickname</Form.Label>
                            <Form.Control type="text" ref={nft_nickname} required placeholder='Bot 1' />
                        </Form.Group>
                    </Col>
                </Row>

                <Row className='mt-3'>
                    <Col md='12' lg='6'>
                        <Form.Group id="contract-address">
                            <Form.Label>NFT Contract Address</Form.Label>
                            <Form.Control type="text" ref={contractAddress} required placeholder='0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d' />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group id="tokenId">
                            <Form.Label>Token ID</Form.Label>
                            <Form.Control type="text" ref={tokenId} required placeholder='3030' />
                        </Form.Group>
                    </Col>
                </Row>


                <Row className='mt-3'>
                    <Col md='12' lg='6'>
                        <Form.Group id="o-api-key">
                            <Form.Label>OpenSea API key</Form.Label>
                            <Form.Control type="text" ref={oApiKey} required placeholder="Insert API key here" />
                        </Form.Group>
                    </Col>
                    <Col></Col>
                </Row>
                <Row className='mt-3'>
                    <Col md='12' lg='6'>
                        <Form.Group id="buy-floor-price">
                            <Form.Label>Floor Buy Price (in ETH eg. 60)</Form.Label>
                            <Form.Control
                                type="text"
                                ref={floorBuyPrice}
                                placeholder={60}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col md='12' lg='6' >
                        <Form.Group id="start-amount">
                            <Form.Label>Start amount (in ETH eg. 0.01)</Form.Label>
                            <Form.Control
                                type="text"
                                ref={startAmount}
                                placeholder={0.01}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col md='12' lg='6'>
                        <Form.Group id="mnemonic">
                            <Form.Label>MetaMask seed phrase of the account you want to bid from</Form.Label>
                            <Form.Control
                                type="text"
                                ref={mnuemonic}
                                placeholder={'food water ....'}

                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col md='12' lg='6'>
                        <Form.Group id="your-address">
                            <Form.Label>Your Address</Form.Label>
                            <Form.Control
                                type="text"
                                ref={yourAddress}
                                placeholder="0x01...."
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                    </Col>
                </Row>


                <Button className="mt-4" variant="success" size='lg' type="submit">
                    Run
                </Button>
            </Form>
        </div>
    );
};

export default MyForm;
