import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

const app = firebase.initializeApp({
    apiKey: "AIzaSyCR1HuOdyib7Ia7z-S_ZGfsy5Q2E80YOfU",
    authDomain: "trading-bot---joe-dichiara.firebaseapp.com",
    projectId: "trading-bot---joe-dichiara",
    storageBucket: "trading-bot---joe-dichiara.appspot.com",
    messagingSenderId: "499055390736",
    appId: "1:499055390736:web:f8b647ad7f627e2e831a95",
    measurementId: "G-251WHSBKXD"    
})

export const auth = app.auth()
export default app