import React, { useState, useRef, useEffect } from 'react';
import { Dropdown, Form, Alert } from 'react-bootstrap';
import CryptoBot from "./CryptoBot";
import NftBot from "./NftBot";
import {
    Container,
    Button,
    Navbar,
    Nav,
    Tabs,
    Tab,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import app from '../firebase';


export default function Dashboard() {
    const http_url = "https://bitmartbot.com";
    // const http_url = "http://127.0.0.1:5000";
    const { currentUser, logout } = useAuth();
    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState('cryptoBot');
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');

    const handleTabChange = (eventKey) => {
        setActiveTab(eventKey);
    };

    async function handleLogout() {
        try {
            await logout();
            navigate('/login');
        } catch (err) {
            console.log(err.message);
        }
    }

    // Function to clear success and error after a delay
    const clearAlerts = () => {
        setTimeout(() => {
            setError('');
            setSuccess('');
        }, 4000);
    };

    useEffect(() => {
        clearAlerts();
    }, [error, success]);


    return (
        <div
            className="w-100"
            style={{ minHeight: '100vh', backgroundColor: '#161a25' }}
        >
            <Navbar bg="#161a25" expand="lg">
                <Container>
                    <Navbar.Brand style={{ color: 'white' }}>Trading Bot</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Nav>
                        <Button variant="outline-success" onClick={() => handleLogout()}>
                            Log out
                        </Button>
                    </Nav>
                </Container>
            </Navbar>
            <div style={{ width: '100%', float: 'left' }}>
                <Tabs activeKey={activeTab}
                    variant='dark'
                    className="custom-tabs px-3 pt-3"
                    onSelect={handleTabChange} style={{
                        fontWeight: 'bold',
                        fontSize: '17px',
                        marginBottom: '20px'
                    }}>

                    <Tab eventKey="cryptoBot" title="Crypto Bot" style={{


                        fontSize: '16px',
                        marginBottom: '20px'

                    }}>
                        <CryptoBot http_url={http_url} currentUser={currentUser} error={error} success={success} setError={setError} setSuccess={setSuccess} />
                    </Tab>
                    <Tab eventKey="nftBot" title="NFT Bot" style={{

                        fontSize: '16px',
                        marginBottom: '20px'
                    }}>
                        <NftBot http_url={http_url} currentUser={currentUser} />
                    </Tab>
                </Tabs>
                {error && (
                    <Alert variant="danger" style={{ borderRadius: '10px', position: 'fixed', bottom: '30px', left: '50%', transform: 'translateX(-50%)' }}>
                        {error}
                    </Alert>
                )}
                {success && (
                    <Alert variant="success" style={{ borderRadius: '10px', position: 'fixed', bottom: '30px', left: '50%', transform: 'translateX(-50%)' }}>
                        {success}
                    </Alert>
                )}            </div>

        </div>
    );
}
