import React, { useState, useRef, useEffect } from 'react';
import { Form, Button, Dropdown, Row, Col, Alert } from 'react-bootstrap';
import axios from 'axios';

const MyForm = ({ http_url, currentUser, handleSuccess, setError, setSuccess }) => {

    const [sData, setsData] = useState([])
    const [symbols, setSymbols] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [searchTerm, setSearchTerm] = useState("-");

    const [rules, setRules] = useState([]);

    const tradeSize = useRef();
    const apiKey = useRef();
    const secretKey = useRef();
    const memo = useRef();
    const preBuyPercent = useRef();
    const preSellPercent = useRef();
    const sellPercentEMA = useRef();
    const buyPercentEMA = useRef();

    const nickname = useRef();

    const [botActiveStatus, setBotActiveStatus] = useState(false);

    const handleSelectAll = (event) => {
        const checked = event.target.checked;
        setSelectAll(checked);
        setSymbols(checked ?
            sData.filter((val) => {
                if (val.includes(searchTerm.toUpperCase())) { return val }
            })
            : []
        );
    };

    const handleSelectSymbol = (symbol, checked) => {
        const newSymbols = checked
            ? [...symbols, symbol]
            : symbols.filter((s) => s !== symbol);
        setSymbols(newSymbols);
        setSelectAll(newSymbols.length === sData.length);
    };

    const handleSelectRule = (rule, checked) => {
        const newRules = checked
            ? [...rules, rule]
            : rules.filter((s) => s !== rule);
        setRules(newRules);
    };

    useEffect(() => {
        axios
            .get(
                http_url + '/get_symbols',
            )
            .then((response) => {
                console.log(response.data.symbols);
                setsData(response.data.symbols);
            });
    }, [])

    async function launchBot(e) {
        e.preventDefault();
        await axios
            .post(
                http_url + '/launch_bot/' + currentUser.email,
                {
                    settings: {
                        nickname: nickname.current.value,
                        trade_size: tradeSize.current.value,
                        symbols: symbols.join(","),
                        rules: rules.join(", "),
                        api_key: apiKey.current.value,
                        secret_key: secretKey.current.value,
                        memo: memo.current.value,
                        buyPercentEMA: buyPercentEMA.current.value,
                        preBuyPercent: preBuyPercent.current.value,
                        sellPercentEMA: sellPercentEMA.current.value,
                        preSellPercent: preSellPercent.current.value,
                    },
                },
            )
            .then((response) => {

                setBotActiveStatus(true);
                setSuccess("Success started Bot named " + nickname.current.value)
            })
            .catch((error) => {
                console.log(error)
                setError('Unknown error encountered.');
            });
    }


    return (
        <div>
            <Form onSubmit={launchBot} className="dark-form px-3">
                <Row>
                    <Col>
                        <Form.Group id="trade-size" className='my-3'>
                            <Form.Label>Trade Size (Base currency USDT, BTC, ETH)</Form.Label>
                            <Form.Control type="text" ref={tradeSize} required placeholder={5} />
                        </Form.Group>
                    </Col>
                    <Col></Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group id="symbols" className="my-3" >
                            <Form.Label>Ticker Symbols</Form.Label>
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="symbol-dropdown">
                                    Select Symbols
                                </Dropdown.Toggle>
                                <Dropdown.Menu style={{ width: '400px', maxHeight: '400px', overflowY: 'auto' }} className="bg-dark text-light">
                                    <Form.Control
                                        type="text"
                                        placeholder="Search symbols..."
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        id="select-all"
                                        label="Select All"
                                        checked={selectAll}
                                        onChange={handleSelectAll}
                                    />
                                    <Dropdown.Divider />
                                    {sData.map((val) => {
                                        if (val.includes(searchTerm.toUpperCase())) {
                                            return (
                                                <Form.Check
                                                    key={val}
                                                    type="checkbox"
                                                    id={val}
                                                    label={val}
                                                    checked={symbols.includes(val)}
                                                    onChange={(e) => handleSelectSymbol(val, e.target.checked)}
                                                />
                                            );
                                        }


                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group id="buyPercentEMA" className='mt-3'>
                            <Form.Label>Buy Trigger (% Below sell price)</Form.Label>
                            <Form.Control
                                type="text"
                                ref={buyPercentEMA}
                                placeholder={1.0}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>


                <Row>
                    <Col>
                        <Form.Group id="api-key" className='my-3'>
                            <Form.Label>CRO API Key</Form.Label>
                            <Form.Control type="text" ref={apiKey} required />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group id="preBuyPercent" className='mt-3'>
                            <Form.Label>Pre-Buy Trigger (% Candy Cane)</Form.Label>
                            <Form.Control
                                type="text"
                                ref={preBuyPercent}
                                placeholder={0.5}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group id="secret-key" className='my-3'>
                            <Form.Label>CRO Secret Key</Form.Label>
                            <Form.Control type="text" ref={secretKey} required />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group id="sellPercentEMA" className='mt-3'>
                            <Form.Label>Sell Trigger (% Above buy price)</Form.Label>
                            <Form.Control
                                type="text"
                                ref={sellPercentEMA}
                                placeholder={1.0}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group id="memo" className='mt-3'>
                            <Form.Label>CRO Pass phrase</Form.Label>
                            <Form.Control type="text" ref={memo} required disabled={true} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group id="preSellPercent" className='mt-3'>
                            <Form.Label>Pre-Sell Trigger (% Candy Cane)</Form.Label>
                            <Form.Control
                                type="text"
                                ref={preSellPercent}
                                placeholder={0.5}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group id="nickname" className='mt-3'>
                            <Form.Label>Bot Nickname</Form.Label>
                            <Form.Control type="text" ref={nickname} required placeholder={'Must be unique'} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group id="rules" className='mt-3'>
                            <Form.Label>Rules</Form.Label>
                            <Dropdown >
                                <Dropdown.Toggle variant="dark" id="rule-dropdown" className='my-2'>
                                    Knockout Rules
                                </Dropdown.Toggle>
                                <Dropdown.Menu style={{ width: '400px', maxHeight: '200px', overflowY: 'auto' }} className="dropdown-menu-dark">



                                    <Form.Check
                                        key={'riskIndex'}
                                        type="checkbox"
                                        id={'riskIndex'}
                                        label='Risk Index'
                                        checked={rules.includes('riskIndex')}
                                        onChange={(e) => handleSelectRule('riskIndex', e.target.checked)}
                                    />
                                    <Form.Check
                                        key={'priceVolume'}
                                        type="checkbox"
                                        id={'priceVolume'}
                                        label='Price/Volume Index'
                                        checked={rules.includes('priceVolume')}
                                        onChange={(e) => handleSelectRule('priceVolume', e.target.checked)}
                                    />
                                    <Form.Check
                                        key={'inflation'}
                                        type="checkbox"
                                        id={'inflation'}
                                        label='Inflation Index'
                                        checked={rules.includes('inflation')}
                                        onChange={(e) => handleSelectRule('inflation', e.target.checked)}
                                    />
                                    <Form.Check
                                        key={'coinJail'}
                                        type="checkbox"
                                        id={'coinJail'}
                                        label='Coin Jail Rule'
                                        checked={rules.includes('coinJail')}
                                        onChange={(e) => handleSelectRule('coinJail', e.target.checked)}
                                    />
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown >
                                <Dropdown.Toggle variant="dark" id="rule-dropdown2" className='my-2'>
                                    Liquidation Rules
                                </Dropdown.Toggle>
                                <Dropdown.Menu style={{ width: '400px', maxHeight: '200px', overflowY: 'auto' }} className="dropdown-menu-dark">

                                    <Form.Check
                                        key={'liquidation'}
                                        type="checkbox"
                                        id={'liquidation'}
                                        label='Bitcoin Rule'
                                        checked={rules.includes('liquidation')}
                                        onChange={(e) => handleSelectRule('liquidation', e.target.checked)}
                                    />
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown >
                                <Dropdown.Toggle variant="dark" id="rule-dropdown3" className='my-2'>
                                    Compound Rules
                                </Dropdown.Toggle>
                                <Dropdown.Menu style={{ width: '400px', maxHeight: '200px', overflowY: 'auto' }} className="dropdown-menu-dark">

                                    <Form.Check
                                        key={'compound'}
                                        type="checkbox"
                                        id={'compound'}
                                        label='Compound Rule'
                                        checked={rules.includes('compound')}
                                        onChange={(e) => handleSelectRule('compound', e.target.checked)}
                                    />
                                </Dropdown.Menu>
                            </Dropdown>

                        </Form.Group>
                    </Col>
                </Row>









                <Button className="mt-4 btn-lg" variant="success" type="submit">
                    Launch Bot
                </Button>
            </Form>
        </div>
    );
};

export default MyForm;
