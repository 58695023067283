import React, { useState, useRef, useEffect } from 'react';
import { Tab, Tabs, Row, Col, Nav } from 'react-bootstrap';
import Bots from "./NftBotComponents/liveBot";
import NewBot from "./NftBotComponents/newBot";
import axios from 'axios';

export default function CryptoBot({ http_url, currentUser }) {
    const [activeTab, setActiveTab] = useState('liveBot');

    const handleTabChange = (eventKey) => {
        setActiveTab(eventKey);
    };

    const [success, setSuccess] = useState(null);


    function handleSuccess() {
        setSuccess('Simulation started, check Backtests for status.');
    }

    return (
        <div>
            <Tab.Container id="tabs" activeKey={activeTab} onSelect={handleTabChange}>
                <Row>
                    <Col sm={12} md={2}>
                        <Nav variant="pills" className="flex-column" style={{ fontWeight: 500 }}>

                            <Nav.Item>
                                <Nav.Link eventKey="liveBot" className={activeTab === 'liveBot' ? 'text-success bg-dark' : 'text-light'}>LIVE BOT</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="newBot" className={activeTab === 'newBot' ? 'text-success bg-dark' : 'text-light'}>NEW BOT</Nav.Link>
                            </Nav.Item>

                        </Nav>
                    </Col>
                    <Col sm={12} md={10}>
                        <Tab.Content>

                            <Tab.Pane eventKey="liveBot">
                                <Bots http_url={http_url} currentUser={currentUser} handleSuccess={handleSuccess} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="newBot">
                                <NewBot http_url={http_url} currentUser={currentUser} handleSuccess={handleSuccess} />
                            </Tab.Pane>

                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>

        </div>);
}