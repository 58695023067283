import React, { useState, useRef, useEffect } from 'react';
import { Tab, Tabs, Row, Col, Nav } from 'react-bootstrap';
import TradingViewWidget, { Themes } from 'react-tradingview-widget';
import { CSVLink } from 'react-csv';
import Bots from "./CryptoBotComponents/liveBot";
import NewBot from "./CryptoBotComponents/newBot";
import Backtests from "./CryptoBotComponents/backtests";
import NewBacktest from "./CryptoBotComponents/newBacktest";
import axios from 'axios';

export default function CryptoBot({ http_url, currentUser, success, error, setError, setSuccess }) {

    function handleSuccess() {
        setSuccess('Simulation started, check Backtests for status.');
    }

    function setSuccessEx() {
        setSuccess('Success');
    }

    const [activeTab, setActiveTab] = useState('home');

    const handleTabChange = (eventKey) => {
        setActiveTab(eventKey);
    };

    const [tradeReport, setTradeReport] = useState([]);
    useEffect(() => {


        axios
            .get(
                http_url + '/get_trade_report/' +
                currentUser.email,
            )
            .then((response) => {
                console.log(response.data.report);
                setTradeReport(response.data.report);
            });


    }, [success, error]);

    return (
        <div>
            <Tab.Container id="tabs" activeKey={activeTab} onSelect={handleTabChange}>
                <Row>
                    <Col sm={12} md={2}>
                        <Nav variant="pills" className="flex-column" style={{ fontWeight: 500 }}>
                            <Nav.Item>
                                <Nav.Link eventKey="home" className={activeTab === 'home' ? 'text-success bg-dark' : 'text-light'}>HOME</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="liveBot" className={activeTab === 'liveBot' ? 'text-success bg-dark' : 'text-light'}>LIVE BOT</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="newBot" className={activeTab === 'newBot' ? 'text-success bg-dark' : 'text-light'}>NEW BOT</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="backtests" className={activeTab === 'backtests' ? 'text-success bg-dark' : 'text-light'}>BACKTESTS</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="newBacktest" className={activeTab === 'newBacktest' ? 'text-success bg-dark' : 'text-light'}>NEW BACKTEST</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="tradeReport" className={activeTab === 'tradeReport' ? 'text-success bg-dark' : 'text-light'}>TRADE REPORT</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={12} md={10}>
                        <Tab.Content>
                            <Tab.Pane eventKey="home">
                                <TradingViewWidget
                                    symbol="BINANCE:BTCUSDT"
                                    theme={Themes.DARK}
                                    locale="en"
                                    width="100%"
                                    height="400px"
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="liveBot">
                                <Bots http_url={http_url} currentUser={currentUser} handleSuccess={handleSuccess} setError={setError} setSuccess={setSuccessEx} error={error} success={success} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="newBot">
                                <NewBot http_url={http_url} currentUser={currentUser} handleSuccess={handleSuccess} setError={setError} setSuccess={setSuccess} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="backtests" >
                                <Backtests http_url={http_url} currentUser={currentUser} error={error} success={success} setError={setError} setSuccess={setSuccess} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="newBacktest">
                                <NewBacktest http_url={http_url} currentUser={currentUser} handleSuccess={handleSuccess} setError={setError} setSuccess={setSuccess} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="tradeReport">
                                <CSVLink data={tradeReport} separator={','}>
                                    Download trade report
                                </CSVLink>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>

        </div>);
}