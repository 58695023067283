import React, { useState, useRef, useEffect } from 'react';
import { Form, Button, Dropdown, Row, Col, Alert, ListGroup, ListGroupItem, Container } from 'react-bootstrap';
import axios from 'axios';

const MyForm = ({ http_url, currentUser, success, error, setError, setSuccess }) => {

    const [backtests, setBacktests] = useState([]);
    const [fontSize, setFontSize] = useState(16);


    useEffect(() => {
        axios
            .get(
                http_url + '/get_backtests/' +
                currentUser.email,
            )
            .then((response) => {
                console.log(response.data.backtests);
                setBacktests(response.data.backtests);
            });

        function handleResize() {
            if (window.innerWidth < 700) {
                setFontSize(10);
            } else if (window.innerWidth < 1000) {
                setFontSize(12);
            }
            else {
                setFontSize(16);
            }
        }

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, [success, error])

    async function handleDel(title) {
        axios
            .get(
                http_url + '/delete_backtest/' +
                currentUser.email + '/' + title,
            )
            .then((response) => {
                console.log(response.data);
                setBacktests(backtests.filter((val) => { return val.title !== title }));
                setSuccess('Success');
            }).catch((err) => {
                setError('Error encountered: ' + err);
            });
    }

    return (
        <Container className="bg-dark text-light" style={{ width: '100%' }}>
            <Row className='py-4 header-row' style={{ fontSize: `${fontSize}px` }}>
                <Col xs={2}>
                    NAME
                </Col>
                <Col xs={2}>
                    DATE RANGE
                </Col>
                <Col xs={2}>
                    COINS
                </Col>
                <Col xs={2}>
                    PERFORMANCE (Win/Loss)
                </Col>
                <Col xs={2}>
                    ACTIONS
                </Col>
                <Col xs={2}>
                    STATUS
                </Col>
            </Row>

            {backtests.map((backtest) => {
                return (
                    <Row className="py-2 align-items-center mx-0 px-0" style={{ fontSize: `${fontSize}px`, textAlign: 'center' }} key={backtest.title}>
                        <Col xs={2} style={{ fontWeight: 500, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {backtest.title.length > 15 ? `${backtest.title.slice(0, 12)}...` : backtest.title}
                        </Col>
                        <Col xs={2} style={{ fontWeight: 500 }}>
                            {backtest.settings.start_date}  -  {backtest.settings.end_date}
                        </Col>
                        <Col xs={2} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {backtest.settings.symbols.length > 20 ? `${backtest.settings.symbols.slice(0, 17)}...` : backtest.settings.symbols}
                        </Col>
                        <Col xs={2}>
                            ⬆{backtest.results.average_win}% / ⬇{backtest.results.average_loss}%
                        </Col>
                        <Col xs={2}>
                            <Button variant='danger' size={fontSize < 16 ? 'sm' : 'md'} onClick={() => handleDel(backtest.title)}>Delete</Button>
                        </Col>
                        <Col xs={2}>
                            <Button variant={backtest.status === 'Complete' ? 'success' : 'secondary'} size={fontSize < 16 ? 'sm' : 'md'} disabled>{backtest.status}</Button>
                        </Col>
                    </Row>
                );
            })}

        </Container>
    );
};

export default MyForm;
