import React, { useEffect, useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import axios from 'axios';

const BotCard = ({ http_url, bot_data, coins, fontSize, handlePause, handleDel, handleResume, handleSell, handleBuy }) => {
    const cardStyle = {
        borderRadius: '20px',
        background: 'linear-gradient(45deg, #332940, #004d6e, #001f3f)',
        padding: '20px'
    };

    const [buyButtonDisabled, setBuyButtonDisabled] = useState(false);
    const [bought, setBought] = useState(bot_data.bought);
    const [price, setPrice] = useState(0)

    let trimmedValue = 0;
    if (bot_data.entry) {
        const calculatedValue = Number(bot_data.entry) * (1 + Number(bot_data.sell_percent) / 100);
        const formattedValue = calculatedValue.toFixed(5);

        // Ensure the formatted value doesn't have more than 5 decimal places
        trimmedValue = formattedValue.replace(/\.?0*$/, '');
    }


    console.log(trimmedValue);
    const handleClick = (isBuy) => {
        // Disable the button
        setBuyButtonDisabled(true);

        // Perform the buy action
        isBuy ? handleBuy(bot_data.nickname) : handleSell(bot_data.nickname);

        // Enable the button after 10 seconds
        setTimeout(() => {
            setBought(isBuy ? true : false);
            setBuyButtonDisabled(false);
        }, 10000);
    };

    const getCurrentPrice = async () => {
        axios
            .get(
                http_url + '/get_price/' + bot_data.coins[0],
            )
            .then((response) => {
                let data = response.data
                console.log(data);
                setPrice(data.price);
            }
            )
            .catch((err) => {
                console.log('Error encountered: ' + err);
            });

    }
    useEffect(() => {
        getCurrentPrice();
    }, [])

    return (
        <Card style={cardStyle} className='mb-3'>
            <Card.Body>
                <Card.Title style={{ fontWeight: 'bold' }}>{bot_data.nickname.length > 25 ? `${bot_data.nickname.slice(0, 25)}...` : bot_data.nickname}</Card.Title>
                <Card.Text>
                    <strong>Coins:</strong>  {(function () {
                        try {
                            if (coins.length > 20) {
                                return coins.join(', ').slice(0, 17) + '...';
                            } else {
                                return coins.join(', ');
                            }

                        } catch (error) {
                            console.log(coins)
                            return null
                        }

                    }
                    )()}
                </Card.Text>
                <Card.Text className="d-flex justify-content-between" style={{ width: '100%' }}>
                    <div>
                        <strong>Bought </strong>
                        <p>${(Number(bot_data.trade_size) + bot_data.average_win_amount).toFixed(2)}</p>
                    </div>
                    <div>
                        <strong>{bot_data.average_win >= 0 ? 'Profit 🟢 ' : 'Loss 🔴 '}</strong>
                        <p>{Number(bot_data.average_win).toFixed(2)} %</p>
                    </div>
                    <div>
                        <strong>{bot_data.average_win >= 0 ? 'USDT Profit 💸' : 'USDT Loss 🔻'}</strong>
                        <p>{Number(bot_data.average_win_amount).toFixed(2)}</p>
                    </div>
                </Card.Text>
                <Card.Text className="d-flex justify-content-between" style={{ width: '100%' }}>
                    <div>
                        <strong>Buy %{Number(bot_data.buy_percent).toFixed(2)}</strong>
                    </div>
                    <div>
                        <strong>Sell %{Number(bot_data.sell_percent).toFixed(2)}</strong>
                    </div>
                    <div>
                        <strong>Candy Cane %{Number(bot_data.candy_percent).toFixed(2)}</strong>
                    </div>
                </Card.Text>
                <Card.Text className="d-flex justify-content-between" style={{ width: '100%' }}>
                    <div>
                        Current {price}
                    </div>
                    <div>
                        Entry {bot_data.entry ? Number(bot_data.entry) : "None"}
                    </div>
                    <div>
                        Target {bot_data.entry ? trimmedValue : "None"}
                    </div>
                </Card.Text>
                {
                    bought ? <Button variant='warning' size={fontSize < 16 ? 'sm' : 'md'} className='me-3 mb-3' disabled={buyButtonDisabled} onClick={() => handleClick(false)}>Sell</Button> :
                        <Button variant='secondary' className='me-3 mb-3' size={fontSize < 16 ? 'sm' : 'md'} onClick={() => handleClick(true)}
                            disabled={buyButtonDisabled}>Buy</Button>
                }
                {
                    bot_data.paused === '0' ? <Button variant='warning' size={fontSize < 16 ? 'sm' : 'md'} className='me-3 mb-3' onClick={() => handlePause(bot_data.nickname)}>Pause</Button> :
                        <Button variant='success' className='me-3 mb-3' size={fontSize < 16 ? 'sm' : 'md'} onClick={() => handleResume(bot_data.nickname)}>Resume</Button>
                }
                <Button variant='danger' className='me-3 mb-3' size={fontSize < 16 ? 'sm' : 'md'} onClick={() => handleDel(bot_data.nickname)}>Delete</Button>
                <Button variant='success' className='mb-3' size={fontSize < 16 ? 'sm' : 'md'} onClick={() => { }}>Modify</Button>
            </Card.Body>
        </Card>
    );
};

export default BotCard;
