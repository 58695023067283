import React, { useState, useRef, useEffect } from 'react';
import { Form, Button, Dropdown, Row, Col, Alert, ListGroup, ListGroupItem, Container } from 'react-bootstrap';
import axios from 'axios';

const MyForm = ({ http_url, currentUser }) => {

    const [nftBots, setNftBots] = useState([]);
    const [error, setError] = useState('');
    const [fontSize, setFontSize] = useState(16);


    useEffect(() => {


        axios
            .get(
                http_url + '/get_nft_bots/' +
                currentUser.email,
            )
            .then((response) => {
                console.log(response.data.bots)
                setNftBots(response.data.bots);
            })
            .catch((error) => {
                setError('Unknown error encountered.');
            });





        function handleResize() {
            if (window.innerWidth < 700) {
                setFontSize(10);
            } else if (window.innerWidth < 1000) {
                setFontSize(12);
            }
            else {
                setFontSize(16);
            }
        }

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, [])

    async function handleDel(nickname) {
        axios
            .post(
                http_url + '/stop-nft-script/' +
                currentUser.email + '/' + nickname,
            )
            .then((response) => {
                console.log(response.data);
                setNftBots(nftBots.filter((val) => { return val.nickname !== nickname }));
            });
    }

    return (
        <Container className="bg-dark text-light" style={{ width: '100%' }}>
            <Row className='py-4 header-row' style={{ fontSize: `${fontSize}px` }}>
                <Col xs={2}>
                    BOT
                </Col>
                <Col xs={3}>
                    NFT COLLECTION
                </Col>
                <Col xs={3}>
                    CONTRACT
                </Col>
                <Col xs={2}>
                    TOKEN ID
                </Col>
                <Col xs={2}>
                    ACTIONS
                </Col>
            </Row>

            {nftBots.map((bot, i) => {
                return (
                    <Row className="py-2 align-items-center mx-0 px-0" style={{ fontSize: `${fontSize}px`, textAlign: 'center' }} key={i}>
                        <Col xs={2} style={{ fontWeight: 500, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {bot.nickname.length > 15 ? `${bot.nickname.slice(0, 12)}...` : bot.nickname}
                        </Col>

                        <Col xs={3} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {bot.nftCollection}
                        </Col>
                        <Col xs={3}>
                            {bot.contractAddress}
                        </Col>
                        <Col xs={2}>
                            {bot.tokenId}
                        </Col>
                        <Col xs={2} style={{ display: 'flex', justifyContent: 'space-around' }}>

                            <Button variant='danger' size={fontSize < 16 ? 'sm' : 'md'} onClick={() => handleDel(bot.nickname)}>Delete</Button>

                        </Col>

                    </Row>
                );
            })}

        </Container>
    );
};

export default MyForm;
