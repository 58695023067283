import React, { useState, useRef, useEffect } from 'react';
import { Form, Button, Dropdown, Row, Col, Alert } from 'react-bootstrap';
import axios from 'axios';

const MyForm = ({ http_url, currentUser, handleSuccess, setSuccess, setError }) => {

    const backtestTitle = useRef();
    const startDate = useRef();
    const endDate = useRef();
    const minPriceVolumeIndex = useRef();
    const preBuyPercent = useRef();
    const preSellPercent = useRef();
    const maxLowEntryGap = useRef();
    const candyCanePercent = useRef();

    const [botActiveStatus, setBotActiveStatus] = useState(false);

    const [symbols, setSymbols] = useState([]);


    function handleNewBacktest(e) {
        e.preventDefault();
        axios
            .post(
                http_url + '/start_backtest/' +
                currentUser.email +
                '/' +
                backtestTitle.current.value,
                {
                    start_date: startDate.current.value,
                    end_date: endDate.current.value,
                    symbols: symbols.current.value,
                    min_price_volume_index: minPriceVolumeIndex.current.value,
                    pre_buy_percent: preBuyPercent.current.value,
                    pre_sell_percent: preSellPercent.current.value,
                    max_low_entry_gap: maxLowEntryGap.current.value,
                    candy_cane_percent: candyCanePercent.current.value,
                },
            )
            .then((response) => {
                setSuccess('Simulation started, check Backtests for status.');
            })
            .catch((error) => {
                setError('Unknown error encountered.');
            });
    }


    return (
        <div>

            <Form onSubmit={handleNewBacktest} className="dark-form px-3">
                <Row>
                    <Col>
                        <Form.Group id="backtest-title">
                            <Form.Label>Backtest Title</Form.Label>
                            <Form.Control type="text" ref={backtestTitle} required />
                        </Form.Group>
                    </Col>
                    <Col>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group id="start-date">
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control type="date" ref={startDate} required />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group id="end-date">
                            <Form.Label>End Date</Form.Label>
                            <Form.Control type="date" ref={endDate} required />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group id="symbols">
                            <Form.Label>Symbols (Separate with commas)</Form.Label>
                            <Form.Control type="text" ref={symbols} required placeholder="BTCUSDT,ETHUSDT" />
                        </Form.Group>
                    </Col>
                    <Col>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group id="min-price-volume-index">
                            <Form.Label>Minimum Price Volume Index</Form.Label>
                            <Form.Control
                                type="number"
                                ref={minPriceVolumeIndex}
                                defaultValue={1350000}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group id="pre-buy-percent">
                            <Form.Label>Pre-BUY EMA Gap %</Form.Label>
                            <Form.Control
                                type="number"
                                ref={preBuyPercent}
                                defaultValue={1.6}
                                step={0.1}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group id="pre-sell-percent">
                            <Form.Label>Pre-SELL EMA Gap %</Form.Label>
                            <Form.Control
                                type="number"
                                ref={preSellPercent}
                                defaultValue={1.4}
                                step={0.1}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Form.Group id="max-low-entry-gap">
                            <Form.Label>Max Low-Entry Gap %</Form.Label>
                            <Form.Control
                                type="number"
                                ref={maxLowEntryGap}
                                defaultValue={5.0}
                                step={0.1}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group id="candy-cane-percent">
                            <Form.Label>Candy Cane %</Form.Label>
                            <Form.Control
                                type="number"
                                ref={candyCanePercent}
                                defaultValue={1.0}
                                step={0.1}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>
















                <Button className="mt-4" variant="success" type="submit">
                    Start Backtest
                </Button>
            </Form>
        </div>
    );
};

export default MyForm;
