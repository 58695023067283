import React, { useState, useRef, useEffect } from 'react';
import { Form, Button, Dropdown, Row, Col, Alert, ListGroup, ListGroupItem, Container } from 'react-bootstrap';
import axios from 'axios';
import BotCard from './botCard';

const MyForm = ({ http_url, currentUser, success, error, setSuccess, setError }) => {

    const [bots, setBots] = useState([]);

    const [coins, setCoins] = useState([]);

    const [fontSize, setFontSize] = useState(16);

    useEffect(() => {
        axios
            .get(
                http_url + '/get_bots/' +
                currentUser.email,
            )
            .then((response) => {
                console.log(response.data.bots);
                setBots(response.data.bots);
                let t_coins = []
                response.data.bots.forEach((val, i) => t_coins.push(val.coins))
                setCoins(t_coins)
                console.log(coins)
            });

        function handleResize() {
            if (window.innerWidth < 700) {
                setFontSize(10);
            } else if (window.innerWidth < 1000) {
                setFontSize(12);
            }
            else {
                setFontSize(16);
            }
        }

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, [success, error])

    async function handleDel(nickname) {
        try {
            const response = await axios.post(
                http_url + '/stop_bot/' + currentUser.email + '/' + nickname
            );

            console.log(response.data);
            setBots(bots.filter((val) => val.nickname !== nickname));
            setSuccess('Success');
        } catch (error) {
            console.log('Error encountered:', error);
            setError('Error encountered: ' + error);
        }
    }

    async function handlePause(nickname) {
        axios
            .get(
                http_url + '/pause_bot/' +
                currentUser.email + '/' + nickname,
            )
            .then((response) => {
                console.log(response.data);
                setSuccess('Success');
            })
            .catch((err) => {
                setError('Error encountered: ' + err);
            });
    }

    async function handleResume(nickname) {
        axios
            .get(
                http_url + '/resume_bot/' +
                currentUser.email + '/' + nickname,
            )
            .then((response) => {
                console.log(response.data);
                setSuccess('Success');
            })
            .catch((err) => {
                setError('Error encountered: ' + err);
            });
    }

    async function handleBuy(nickname) {
        axios
            .post(
                http_url + '/market_buy/' +
                currentUser.email + '/' + nickname,
            )
            .then((response) => {
                console.log(response.data);
                setBots(bots.map((val) => { return val.nickname === nickname ? { ...val, bought: true } : val }));
                setSuccess('Success');
            }
            )
            .catch((err) => {
                setError('Error encountered: ' + err);
            });
    }

    async function handleSell(nickname) {
        axios
            .post(
                http_url + '/market_sell/' +
                currentUser.email + '/' + nickname,
            )
            .then((response) => {
                console.log(response.data);
                setBots(bots.map((val) => { return val.nickname === nickname ? { ...val, bought: false } : val }));
                setSuccess('Success');
            })
            .catch((err) => {
                setError('Error encountered: ' + err);
            });
    }


    async function handlePanicSell(nicknames) {
        axios
            .post(
                http_url + '/panic_sell/' +
                currentUser.email + '/' + nicknames,
            )
            .then((response) => {
                console.log(response.data);
                setBots(bots.map((val) => { return { ...val, bought: false } }));
                setSuccess('Success');
            })
            .catch((err) => {
                setError('Error encountered: ' + err);
            });
    }

    return (
        <Container className="text-light" style={{ width: '100%' }}>
            <Row xs={1} md={2} lg={3}>
                {bots.map((bot, i) => (
                    <Col key={i} className="mb-4">
                        <BotCard
                            http_url={http_url}
                            bot_data={bot}
                            coins={coins[i]}
                            fontSize={fontSize}
                            handleDel={handleDel}
                            handlePause={handlePause}
                            handleResume={handleResume}
                            handleBuy={handleBuy}
                            handleSell={handleSell}
                        />
                    </Col>
                ))}
            </Row>
            <Button
                variant="danger"
                className="btn-lg text-light"
                style={{
                    borderRadius: '20px',
                    position: 'fixed',
                    bottom: '50px',
                    right: '50px',
                    transition: 'width 0.3s ease-in-out',
                }}
                onClick={() => handlePanicSell(bots.map((val) => val.nickname).join(','))}
            >
                💣 Panic Sell
            </Button>
        </Container>
    );

};

export default MyForm;
