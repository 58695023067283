import React from 'react'

import PrivateRoute from './components/PrivateRoute'
import Signup from './components/Signup'
import Login from './components/Login'
import Text from './components/Text'

import { Container } from 'react-bootstrap'
import { AuthProvider } from './contexts/AuthContext'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

function App() {
  return (
    <div className='d-flex align-items-center justify-content-center'
      style={{ minHeight: '100vh' }}
    >
      <div className='w-100 d-flex align-items-center justify-content-center'>
        <Router>
          <AuthProvider>
            <Routes>
              <Route path='/login' element={<Login />} />
              <Route path='/signup' element={<Signup />} />
              <Route exact path='/' element={<PrivateRoute />} />

            </Routes>
          </AuthProvider>
        </Router>
      </div>
    </div>
  );
}

export default App;
